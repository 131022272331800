/**
 * Do not modify this file. Add pages to BaseRoutes.jsx instead.
 * */

import './App.css';
import BaseRoutes from "./BaseRoutes";
import { BrowserRouter as Router } from "react-router-dom";
import {createTheme, ThemeProvider} from "@mui/material";
import {BaseTheme} from "./theme/Theme";

function App() {
    const baseTheme = createTheme(BaseTheme);

    return (
        <ThemeProvider theme={baseTheme}>
            <Router className="App">
                <BaseRoutes />
            </Router>
        </ThemeProvider>
    );
}

export default App;
