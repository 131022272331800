// Calculate the price for 0.1 liters based on available pricing information from a bar
export const calculateFor01 = ({ bar }) => {
    // Check if bar data or bar's price information is missing
    if (!bar || !bar.price) {
        console.error('Bar or bar price data is missing:', bar);
        return 'N/A'; // Return 'N/A' if data is incomplete
    }

    // Array to hold possible prices calculated for 0.1 liter
    const prices = [];

    // Direct price for 0.1 liter if available
    if (bar.price.for01) {
        prices.push(parseFloat(bar.price.for01));
    }
    // Calculate price for 0.1 liter from the price for 0.3 liters
    if (bar.price.for03) {
        prices.push(parseFloat(bar.price.for03) / 3);
    }
    // Calculate price for 0.1 liter from the price for 0.4 liters
    if (bar.price.for04) {
        prices.push(parseFloat(bar.price.for04) / 4);
    }
    // Calculate price for 0.1 liter from the price for 0.5 liters
    if (bar.price.for05) {
        prices.push(parseFloat(bar.price.for05) / 5);
    }
    // Calculate price for 0.1 liter from custom pricing options
    if (bar.price.customPrices) {
        bar.price.customPrices.forEach(customPrice => {
            if (customPrice.price && customPrice.pricePer) {
                prices.push(parseFloat(customPrice.price) / parseFloat(customPrice.pricePer));
            }
        });
    }

    // Return the minimum calculated price formatted to two decimal places, or 'N/A' if no prices were calculable
    return prices.length > 0 ? Math.min(...prices).toFixed(2) : 'N/A';
};
