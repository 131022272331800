import React from 'react';
import { Box, Typography } from '@mui/material';
import { getColorForPrice } from './GetColors';  // Import to get the color based on the price
import { calculateFor01 } from "./CalculateFor01"; // Import to calculate the price per deciliter
import '../theme/colors.css'; // Import theme colors for consistent styling

// BarListItem component accepts a bar object, price range, selection state, and a function for handling selection
const BarListItem = ({ bar, priceRange, isSelected, onSelect }) => {
    // Calculate the price for this particular bar using the calculateFor01 function
    const price = calculateFor01({bar})
    // Determine the color for the price background using the getColorForPrice function
    const color = getColorForPrice(price, priceRange);

    return (
        // Container Box with flex layout to align items and justify content
        <Box className={"card-item"} sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'start',
            padding: '12px',
            borderRadius: "16px",
            marginBottom: "12px",
            cursor: 'pointer',
            transition: '0.15s',
            backgroundColor: isSelected ? "var(--color-accent-300)" : "var(--color-accent-100)" // Background color changes if item is selected
        }} onClick={onSelect}> {/* onClick triggers the onSelect function passed as a prop */}
            {/* Left section for name and address */}
            <Box sx={{ flex: 1, paddingRight: 4 }}>
                {/* Bar name displayed with larger typography */}
                <Typography variant="h6" component="h3" color='var(--color-primary-light)'>
                    {bar.name}
                </Typography>
                {/* Bar address displayed with smaller typography */}
                <Typography variant="body2" color="var(--color-secondary-light)">
                    {bar.address}
                </Typography>
            </Box>
            {/* Right section for price */}
            <Box sx={{
                padding: '6px 12px',
                backgroundColor: `#${color}`, // Background color calculated from the bar's price
                borderRadius: '12px', // Rounded corners for the price tag
                color: '#fff', // White text color for better visibility
            }}>
                {/* Display the price per deciliter */}
                <Typography variant="body1" color="var(--color-primary-light)">
                    {price} €/dl
                </Typography>
            </Box>
        </Box>
    );
};

export default BarListItem;
