import React, { useState, useEffect, useRef } from 'react';

import BarDisplay from './BarDisplay';
import {child, get, ref} from "firebase/database";
import {FIREBASE_DATABASE} from "../index";

function BarParentComponent({ barData, resetBarData, moderation, onUpdate }) {
    const [isBarDisplayOpen, setIsBarDisplayOpen] = useState(true);
    const barDisplayRef = useRef(null);  // Refference: track the BarDisplay DOM node
    const databaseRef = ref(FIREBASE_DATABASE,moderation ? "unmoderated" : "live");
    const [barObject,setBarObject] = useState(null);

    const loadData = () => {
        get(child(databaseRef, barData)).then((snapshot) => {
            if (snapshot.exists()) {
                setBarObject(snapshot.val());
                setIsBarDisplayOpen(true);
            } else {
                console.log("No data available");
            }
        }).catch((error) => {
            console.error(error);
        });
    }

    useEffect(() => {
        loadData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [barData]);
  
    const handleClose = () => {
        setIsBarDisplayOpen(false);
        resetBarData();
    };

    // Handle clicks  barComponents to hide/close them
    useEffect(() => {
        function handleClickOutside(event) {
            if (barDisplayRef.current && !barDisplayRef.current.contains(event.target)) {
                handleClose();  // Close BarDisplay if: click is outside
            }
        }

        if (isBarDisplayOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isBarDisplayOpen]);

    return (
        <div>
            {barObject===null?null:<>
                {isBarDisplayOpen && (
                    <div ref={barDisplayRef} className={"barOpener"} onMouseDown={()=>{
                        resetBarData();
                        setIsBarDisplayOpen(false)
                    }}>
                        <BarDisplay moderation={moderation} barData={barObject} isOpen={isBarDisplayOpen} onClose={handleClose} onUpdate={() => {
                            onUpdate();
                            loadData();
                        }} />
                    </div>
                )}
            </>}
        </div>
    );
}

export default BarParentComponent;