import React, {useEffect, useRef, useState} from 'react';
import { Typography, Box, Grid, Container } from '@mui/material';
import ReviewList from './ReviewList';
import CancelIcon from '@mui/icons-material/Cancel';
import {MaterialButton24, MaterialButtonOutlined24, MaterialButtonTonal24} from "../widgets/MaterialButton";
import AddForm from "./AddForm";
import Dialog from "./Dialog";
import {FIREBASE_DATABASE} from "../index";
import {ref, set} from "firebase/database";

// Function -> transform currency to symbol
function getCurrencySymbol(currencyCode) {
    const currencySymbols = {
        USD: '$',
        EUR: '€',
        GBP: '£',
        JPY: '¥',
        INR: '₹',
        // switch logic : currency -> symbol
    };
    return currencySymbols[currencyCode] || currencyCode;
}

// Function -> star rating | 5 stars, colored based on "value"/5
function SimpleRating({ value }) {
    const maxStars = 5;
    return (
        <Box>
            {[...Array(maxStars)].map((star, index) => (
                <span key={index} style={{ color: index < value ? 'gold' : 'grey' }}>{index < value ? '★' : '☆'}</span>
            ))}
        </Box>
    );
}


function BarDisplay({ moderation, barData, isOpen, onClose, onUpdate }) {
    const mapRef = useRef(null);  // Div -> map rendering point

    const [showAddForm, setShowAddForm] = useState(false);
    const [permissionDeniedDialogOpen, setPermissionDeniedDialogOpen] = useState(false);

    const handleFormClose = () => {
        setShowAddForm(false);
        onUpdate();
    };

    // useEffect(() => {
    //     if (isOpen && window.google && mapRef.current) {
    //         const map = new window.google.maps.Map(mapRef.current, {
    //             center: { lat: parseFloat(barData.lat), lng: parseFloat(barData.lng) },
    //             mapId: 'fd752bc7465398e6',
    //             zoom: 15
    //         });
    //         new window.google.maps.Marker({
    //             position: { lat: parseFloat(barData.lat), lng: parseFloat(barData.lng) },
    //             map: map,
    //             title: barData.name
    //         });
    //     }
    // }, [isOpen, barData]);

    if (!isOpen) {
        return null;
    }

    const currencySymbol = getCurrencySymbol(barData.currency);

    const approve = () => {
        const databaseRef = ref(FIREBASE_DATABASE, "live/" + barData.id);

        set(databaseRef, barData).then(() => {
            reject()
        }).catch((error) => {
            if (error.message.includes("PERMISSION_DENIED")) {
                setPermissionDeniedDialogOpen(true);
            } else {
                onClose();
                onUpdate();
            }
            console.error(error);
        })
    }

    const reject = () => {
        const databaseRef = ref(FIREBASE_DATABASE, "unmoderated/" + barData.id);

        set(databaseRef, null).then(() => {
            onClose();
            onUpdate();
        }).catch((error) => {
            if (error.message.includes("PERMISSION_DENIED")) {
                setPermissionDeniedDialogOpen(true);
            } else {
                onClose();
                onUpdate();
            }
            console.error(error);
        })
    }

    return (
        <Container className={"bar-container"} onMouseDown={(e) => {
            e.stopPropagation()
        }} maxWidth="md" sx={{maxHeight: '90vh', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            {showAddForm && <AddForm onClose={handleFormClose} id={barData.id} source={moderation ? "/unmoderated/" : "/live/"} moderation={moderation} />}
            {
                permissionDeniedDialogOpen ?

                <Dialog
                    title={"Permission denied!"}
                    content={"Only administrators can approve or reject places data. Please sign in with an administrator account to continue."}
                    positiveButtonText={"OK"}
                    negativeButtonText={"Cancel"}
                    isConfirmDialog={false}
                    isOpen={permissionDeniedDialogOpen}
                    setOpen={setPermissionDeniedDialogOpen}
                    onPositiveButtonClicked={() => {
                        onClose();
                        onUpdate();
                    }}
                    onNegativeButtonClicked={() => {
                        onClose();
                        onUpdate();
                    }}
                /> : null
            }
            <div style={{
                width: "100%",
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: "48px"
            }}>
                <button className={"back-button"} onClick={onClose}>
                    <CancelIcon/>
                </button>
                &nbsp;&nbsp;
                <Typography sx={{
                    padding: "0",
                    margin: "0",
                    flexGrow: 1,
                    fontSize: "1.5rem",
                    overflowX: "auto",
                    whiteSpace: "nowrap",
                    width: "calc(100% - 324px)"
                }} variant="h4" component="div"
                            style={{color: 'var(--color-primary-light)'}}>
                    {moderation ? "Moderation: " : null}{barData.name}
                </Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <MaterialButtonTonal24 onClick={() => {
                    setShowAddForm(true);
                }}>Edit</MaterialButtonTonal24>
                {
                    moderation ? <div>
                        &nbsp;&nbsp;&nbsp;
                        <MaterialButtonOutlined24 onClick={reject}>Reject</MaterialButtonOutlined24>
                        &nbsp;&nbsp;&nbsp;
                        <MaterialButton24 onClick={approve}>Approve</MaterialButton24>
                    </div> : <div></div>
                }
            </div>
            <Box sx={{width: '100%', display: 'flex', justifyContent: 'center', flexGrow: 1, overflow: 'auto'}}>
                <div style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    overflow: 'hidden',
                    maxHeight: 'calc(100vh - 64px)'
                }}>
                    <Grid container sx={{
                        overflow: 'auto'
                    }}>
                        <Grid item xs={6}>
                            <img className={"bar-photo"} src={barData.photo} alt={`View of ${barData.name}`}/>
                            <div className={"bar-map"} ref={mapRef}></div>
                        </Grid>
                        <Grid item xs={6}>
                            <div style={{
                                marginTop: "16px",
                                marginLeft: "16px",
                            }}>
                                <Box sx={{bgcolor: 'var(--color-accent-200)', borderRadius: '16px', p: 2, mb: 2}}>
                                    <Typography variant="body1" color="text.secondary">
                                        {barData.description}
                                    </Typography>
                                </Box>
                                <Typography variant="body2" sx={{mt: 1, color: 'var(--color-primary-light)'}}>
                                    Address: {barData.address}
                                </Typography>
                                <Typography variant="body2" sx={{mt: 1, color: 'var(--color-primary-light)'}}>
                                    Phone: {barData.phone}
                                </Typography>
                                <Typography variant="body2" sx={{mt: 1, color: 'var(--color-secondary-light)'}}
                                            component="a" href={barData.website} target="_blank"
                                            rel="noopener noreferrer">
                                    Website: {barData.website}
                                </Typography>
                                <Box sx={{display: 'flex', alignItems: 'center', mt: 1}}>
                                    <Typography variant="body2" sx={{mr: 1, color: 'var(--color-primary-light)'}}>
                                        Rating: {barData.rating}
                                    </Typography>
                                    <SimpleRating value={parseFloat(barData.rating)}/>
                                </Box>
                                <br/>
                                <Typography variant="h5" sx={{mt: 1, color: 'var(--color-secondary-light)'}}>
                                    Price: {currencySymbol}{barData.price.for01}
                                </Typography>
                                <br/>
                                <Typography variant="h5" sx={{mt: 1, color: 'var(--color-secondary-light)'}}>
                                    Reviews
                                </Typography>
                                <Box sx={{mt: 1}}>
                                    <ReviewList reviews={barData.reviews}/>
                                </Box>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Box>
        </Container>
    );
}

export default BarDisplay;