
// Retrieve CSS color variables from the root style
export const colors = (variable) => {
    const style = getComputedStyle(document.documentElement); // Access computed styles on the root
    return style.getPropertyValue('--color-'+ variable); // Fetch and return the value of a CSS variable
};


// Calculate color for a bar based on its price relative to the overall price range
export const getColorForPrice = (price, priceRange) => {
    const r = 139;
    const g = 100;
    const b = 0; // Constant blue component (not used here)
    if (priceRange.min === priceRange.max || priceRange.length === 0) return `${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
    // Calculate the position of the price within the range as a fraction between 0 and 1
    const fraction = (price - priceRange.min) / (priceRange.max - priceRange.min);
    // Determine the color by interpolating between two colors based on the fraction
    return interpolateColor(fraction);
};


// Interpolate a hexadecimal color value between dark green and dark red based on a fraction
function interpolateColor(fraction) {
    // Calculate red and green values based on the fraction; no blue component is used here
    const r = Math.round(fraction * 139); // Dark red at fraction 1
    const g = Math.round(100 + fraction * (-100)); // Dark green at fraction 0
    const b = 0; // Constant blue component (not used here)
    // Return the hexadecimal color code, combining r, g, and b values
    return `${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
}
