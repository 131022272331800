import React, {useState, useRef, useEffect} from 'react';
import {Box, TextField, Typography, FormControlLabel, Switch, CircularProgress} from '@mui/material';
import {
    MaterialButton24,
    MaterialButtonOutlined24,
    MaterialButtonTonal24
} from "../widgets/MaterialButton";

import {v4 as uuid} from "uuid";
import {FIREBASE_AUTH, FIREBASE_DATABASE} from "../index";
import {get, ref, set} from "firebase/database";
import Dialog from "./Dialog";

const defaultData = {
    id: '',
    name: '',
    description: '',
    address: '',
    lat: '',
    lng: '',
    rating: '0',
    phone: '',
    website: '',
    photo: '',
    currency: '',
    price: {
        for01: '',
        for03: '',
        for04: '',
        for05: '',
        timestamp: 0,
        customPrices: [],
    },
    reviews: []
}

const AddForm = ({onClose, id, source}) => {
    const [showCustomPrice, setShowCustomPrice] = useState(false);
    const [customPrice, setCustomPrice] = useState('');
    const [pricePer, setPricePer] = useState('');
    const [errors, setErrors] = useState({});
    const [approvalDialogOpen, setApprovalDialogOpen] = useState(false);
    const [formData, setFormData] = useState(null);

    const [authState, setAuthState] = useState(1); // State to store the authentication state

    useEffect(() => {
        FIREBASE_AUTH.onAuthStateChanged(user => {
            setAuthState(user ? 2 : 3); // Set the state based on the user's authentication status
        })
    }, []);

    useEffect(() => {
        if (id !== null && id !== undefined) {
            const path = source !== null && source !== undefined ? source + id : "/live/" + id;
            console.log("Path", path);
            const databaseRef = ref(FIREBASE_DATABASE, path)

            get(databaseRef).then((snapshot) => {
                if (snapshot.exists()) {
                    let data = snapshot.val();
                    console.log("Data loaded", data);
                    let newFormData = defaultData;

                    if (data.price !== undefined) {
                        if (data.price.customPrices !== undefined) newFormData.price.customPrices = data.price.customPrices;
                        if (data.price.for01 !== undefined) newFormData.price.for01 = data.price.for01;
                        if (data.price.for03 !== undefined) newFormData.price.for03 = data.price.for03;
                        if (data.price.for04 !== undefined) newFormData.price.for04 = data.price.for04;
                        if (data.price.for05 !== undefined) newFormData.price.for05 = data.price.for05;
                        if (data.price.timestamp !== undefined) newFormData.price.timestamp = data.price.timestamp;
                    }
                    if (data.id !== undefined) newFormData.id = data.id;
                    if (data.name !== undefined) newFormData.name = data.name;
                    if (data.description !== undefined) newFormData.description = data.description;
                    if (data.address !== undefined) newFormData.address = data.address;
                    if (data.lat !== undefined) newFormData.lat = data.lat;
                    if (data.lng !== undefined) newFormData.lng = data.lng;
                    if (data.rating !== undefined) newFormData.rating = data.rating;
                    if (data.phone !== undefined) newFormData.phone = data.phone;
                    if (data.website !== undefined) newFormData.website = data.website;
                    if (data.photo !== undefined) newFormData.photo = data.photo;
                    if (data.currency !== undefined) newFormData.currency = data.currency;
                    if (data.reviews !== undefined) newFormData.reviews = data.reviews;

                    console.log("Data loaded", newFormData);
                    setFormData(newFormData);
                } else {
                    console.log("No data available");
                    setFormData(defaultData);
                }
            }).catch((error) => {
                console.error(error);
                setFormData(defaultData)
            });
        } else {
            setFormData(defaultData);
        }
    }, [id, source]);

    const isFormValid = () => {
        const { name, address, currency, lat, lng} = formData;
        const newErrors = {};

        if (!name.trim()) {
            newErrors.name = 'Name is required';
        }

        if (!address.trim()) {
            newErrors.address = 'Address is required';
        }

        if (!currency.trim()) {
            newErrors.currency = 'Currency is required';
        }

        if (!lat.trim()) {
            newErrors.lat = 'Latitude is required';
        }

        if (!lng.trim()) {
            newErrors.lng = 'Longitude is required';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0; // Form is valid if no errors exist
    };


    const handleSave = (event) => {
        event.preventDefault();

        if (!isFormValid()) {
            alert('Please fill out all required fields.');
            return;
        }

        // Check if at least one price is provided
        let hasAnyPrice = false;

        Object.values(formData.price).forEach(v => {
            if (v.toString().trim() !== "") hasAnyPrice = true
        })

        console.log(hasAnyPrice);
        if (!hasAnyPrice && formData.price.customPrices.length === 0) {
            alert('Please provide at least one price before saving.');
            return;
        }

        // Add logic to save the form data
        const timestamp = Date.now();

        let uid = id === null || id === undefined ? uuid() : id;

        const prepareData = formData;
        prepareData.id = uid;
        prepareData.price.timestamp = timestamp;

        let databaseRef = ref(FIREBASE_DATABASE, source !== null && source !== undefined ? source + uid : "/live/" + uid)

        set(databaseRef, prepareData).then(() => {
            // Reset form state or close the form
            setFormData(null);
            onClose();
        }).catch((err) => {
            if (err.toString().includes('PERMISSION_DENIED')) {
                let databaseRefU = ref(FIREBASE_DATABASE, "/unmoderated/" + id)

                set(databaseRefU, prepareData).then(() => {
                    setApprovalDialogOpen(true);
                }).catch((err) => {
                    console.log(err);
                    setFormData(null);
                    onClose();
                })
            } else {
                console.error(err);
                setFormData(null);
                onClose();
            }
        })
    };

    const handleDelete = (event) => {
        event.preventDefault();

        let databaseRef = ref(FIREBASE_DATABASE, source !== null && source !== undefined ? source + id : "/live/" + id)

        set(databaseRef, null).then(() => {
            // Reset form state or close the form
            setFormData(null);
            onClose();
        }).catch((err) => {
            console.error(err);
            setFormData(null);
            onClose();
        })
    };

    const handleCancel = () => {
        setFormData(null); // Reset the form data
        onClose(); // Close the form without saving
    };

    const handleInputChange = (field, value) => {
        let data = formData;
        data[field] = value;

        setFormData(data);
    };

    const handlePriceChange = (priceType, value) => {
        let data = formData;
        data.price[priceType] = value;

        setFormData(data);
    };


    const handleCustomPriceChange = (field, value) => {
        if (field === 'customPrice') {
            setCustomPrice(value);
        } else if (field === 'pricePer') {
            setPricePer(value);
        }
    };

    const handleCustomPriceAdd = () => {
        // Check if both customPrice and pricePer are not empty
        if (customPrice.trim() !== '' && pricePer.trim() !== '') {
            // Create a new custom price object
            const newCustomPrice = {
                price: customPrice.trim(),
                pricePer: pricePer.trim(),
            };

            // Update the formData state by appending the new custom price to customPrices array
            setFormData({
                ...formData,
                price: {
                    ...formData.price,
                    customPrices: [...formData.price.customPrices, newCustomPrice],
                },
            });

            // Clear the customPrice and pricePer fields after adding custom price
            setCustomPrice('');
            setPricePer('');
        }
    };

    return (
        <div className={"backdrop"} onMouseDown={handleCancel}>
            {
                approvalDialogOpen ?
                    <Dialog
                        title={"Than you for your contribution!"}
                        content={"Your suggestion has been recorded and will be reviewed by the moderator. Thank you for your contribution! Please Sign In or Register to post without moderation."}
                        positiveButtonText={"OK"}
                        negativeButtonText={"Cancel"}
                        isConfirmDialog={false}
                        isOpen={approvalDialogOpen}
                        setOpen={setApprovalDialogOpen}
                        onPositiveButtonClicked={() => {
                            onClose(); // Close the form after saving
                            setFormData(null);
                        }}
                        onNegativeButtonClicked={() => {
                            onClose(); // Close the form after saving
                            setFormData(null);
                        }}
                    />
                    : null
            }
            {
                formData === null ? <CircularProgress/> :
                    <div className={"addForm"} onMouseDown={(e) => {
                        e.stopPropagation()
                    }}>
                        <form onSubmit={handleSave}>
                            <Box p={2}>
                                <Typography variant="h5" sx={{
                                    marginBottom: '1rem',
                                    color: '#FFFFFF',
                                    width: "100%",
                                    textAlign: "center"
                                }}>Add New Bar</Typography>

                                <TextField label="Name" variant="outlined" fullWidth margin="normal"
                                           defaultValue={id !== undefined ? formData.name : ""}
                                           onChange={(e) => handleInputChange('name', e.target.value)}
                                           required error={!!errors.name} helperText={errors.name}/>
                                <TextField label="Description" variant="outlined" fullWidth margin="normal"
                                           defaultValue={id !== undefined ? formData.description : ""}
                                           onChange={(e) => handleInputChange('description', e.target.value)}/>
                                <TextField label="Address" variant="outlined" fullWidth margin="normal"
                                           defaultValue={id !== undefined ? formData.address : ""}
                                           onChange={(e) => handleInputChange('address', e.target.value)} required
                                           error={!!errors.address} helperText={errors.address}/>
                                <TextField label="Place latitude" variant="outlined" fullWidth margin="normal"
                                           defaultValue={id !== undefined ? formData.lat : ""}
                                           onChange={(e) => handleInputChange('lat', e.target.value)} required
                                           type="number" error={!!errors.lat} helperText={errors.lat}/>
                                <TextField label="Place longitude" variant="outlined" fullWidth margin="normal"
                                           defaultValue={id !== undefined ? formData.lng : ""}
                                           onChange={(e) => handleInputChange('lng', e.target.value)} required
                                           type="number" error={!!errors.lng} helperText={errors.lng}/>
                                <TextField label="Phone" variant="outlined" fullWidth margin="normal"
                                           defaultValue={id !== undefined ? formData.phone : ""}
                                           onChange={(e) => handleInputChange('phone', e.target.value)}/>
                                <TextField label="Website" variant="outlined" fullWidth margin="normal"
                                           defaultValue={id !== undefined ? formData.website : ""}
                                           onChange={(e) => handleInputChange('website', e.target.value)}/>
                                <TextField label="Photo URL" variant="outlined" fullWidth margin="normal"
                                           defaultValue={id !== undefined ? formData.photo : ""}
                                           onChange={(e) => handleInputChange('photo', e.target.value)}/>
                                <TextField label="Currency" variant="outlined" fullWidth margin="normal"
                                           defaultValue={id !== undefined ? formData.currency : ""}
                                           onChange={(e) => handleInputChange('currency', e.target.value)} required
                                           error={!!errors.currency} helperText={errors.currency}/>

                                <FormControlLabel
                                    control={<Switch checked={showCustomPrice}
                                                     onChange={() => setShowCustomPrice(!showCustomPrice)}/>}
                                    label="Show custom price to add"
                                    //labelPlacement="start" // Customize label placement
                                    sx={{color: 'var(--color-accent-800)'}} // Customize label color
                                />

                                {!showCustomPrice && (
                                    <div>
                                        {/* Input fields for standard prices */}
                                        <TextField label="Price for 01" variant="outlined" fullWidth margin="normal"
                                                   defaultValue={id !== undefined ? formData.price.for01 : ""}
                                                   onChange={(e) => handlePriceChange('for01', e.target.value)}
                                                   type="number"/>
                                        <TextField label="Price for 03" variant="outlined" fullWidth margin="normal"
                                                   defaultValue={id !== undefined ? formData.price.for03 : ""}
                                                   onChange={(e) => handlePriceChange('for03', e.target.value)}
                                                   type="number"/>
                                        <TextField label="Price for 04" variant="outlined" fullWidth margin="normal"
                                                   defaultValue={id !== undefined ? formData.price.for04 : ""}
                                                   onChange={(e) => handlePriceChange('for04', e.target.value)}
                                                   type="number"/>
                                        <TextField label="Price for 05" variant="outlined" fullWidth margin="normal"
                                                   defaultValue={id !== undefined ? formData.price.for05 : ""}
                                                   onChange={(e) => handlePriceChange('for05', e.target.value)}
                                                   type="number"/>
                                    </div>
                                )}

                                {showCustomPrice && (
                                    <div>
                                        <TextField
                                            label="Custom Price"
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                            value={customPrice}
                                            onChange={(e) => handleCustomPriceChange('customPrice', e.target.value)}
                                            type="number"
                                        />
                                        <TextField
                                            label="Price Per"
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                            value={pricePer}
                                            onChange={(e) => handleCustomPriceChange('pricePer', e.target.value)}
                                            type="number"
                                        />

                                        <div>
                                            <br/>
                                            <MaterialButtonTonal24 variant="contained" onClick={handleCustomPriceAdd}>
                                                Add Custom Price
                                            </MaterialButtonTonal24>
                                        </div>
                                        {/* Display added custom prices */}
                                        <div className={"customPriceMainBox"}>
                                            <h3 className={"customPriceHeading"}>Added Prices</h3>
                                            {formData.price.customPrices.map((customPrice, index) => (
                                                <div key={index} className={"customPriceBox"}>
                                                    <div>
                                                        <p className={"customPriceText"} style={{
                                                            fontWeight: 'bold'
                                                        }}>Price: {customPrice.price}</p>
                                                        <p className={"customPriceText"}>Price
                                                            Per: {customPrice.pricePer}</p>
                                                    </div>
                                                    <span onClick={() => {
                                                        const reducedList = formData.price.customPrices.filter(e => customPrice !== e)
                                                        handlePriceChange('customPrices', reducedList);
                                                    }}
                                                          className={"customPriceCancelButton material-symbols-outlined"}>remove</span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}

                                <Box className={"dialog-actions"} mt={2}>
                                    {
                                        authState === 2 && id !== undefined && id !== null ? <>
                                            <MaterialButtonTonal24 onClick={handleDelete}>
                                                Delete
                                            </MaterialButtonTonal24>
                                            &nbsp;&nbsp;&nbsp;
                                        </> : null
                                    }
                                    <MaterialButtonOutlined24 onClick={handleCancel}>
                                        Cancel
                                    </MaterialButtonOutlined24>
                                    &nbsp;&nbsp;&nbsp;
                                    <MaterialButton24 type="submit" onClick={handleSave}>
                                        Save
                                    </MaterialButton24>
                                </Box>
                            </Box>
                        </form>
                    </div>

            }
        </div>
    );
};

export default AddForm;