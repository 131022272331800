import React, { useEffect, useState } from 'react';
import { get, ref } from 'firebase/database';
import { FIREBASE_DATABASE } from '../index';  // Ensure this import is correct, it should point to your Firebase config
import SignInButton from "./SignInButton";
import MainMap from "./MainMap";  // Ensure MainMap is correctly imported from its file
import BarList from "./BarList";  // Ensure BarList is correctly imported from its file
import { Box } from '@mui/material';
import {calculateFor01} from "./CalculateFor01";
import BarParentComponent from "./BarParentComponent";
import {MaterialButtonTonal24} from "../widgets/MaterialButton";  // Ensure this utility is correctly implemented and imported

const getDefaultDescription = () => {
    return (`
        What can this assistant do:<br/><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;Answer your question about bars on this site<br/>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;Provide you suggestions for bars
    `);
}

const payload = {
    "name": "Beermap Assistant",
    "chatLocation": "beerMap",
    "icon": "https://beermap.andrax.dev/logo192.png",
    "description": getDefaultDescription()
}

function Home() {
    // State to store the array of bars
    const [bars, setBars] = useState([]);
    const [barsUnmoderated, setBarsUnmoderated] = useState([]);
    // State to store the price range of beers across all bars
    const [priceRange, setPriceRange] = useState({ min: Infinity, max: -Infinity });
    const [priceRangeUnmoderated, setPriceRangeUnmoderated] = useState({ min: Infinity, max: -Infinity });
    // State to track the ID of the selected bar
    const [selectedBarId, setSelectedBarId] = useState(null);

    const [selectedUnmoderatedBarId, setSelectedUnmoderatedBarId] = useState(null)

    const [assistantIsOpen, setAssistantIsOpen] = useState(false);

    const loadData = (live = true) => {
        // Reference to the 'live' node in your Firebase Database
        const liveDataRef = ref(FIREBASE_DATABASE, live ? 'live' : 'unmoderated');

        // Fetch data from Firebase
        get(liveDataRef).then((snapshot) => {
            if (snapshot.exists()) {
                const data = snapshot.val();
                const barMap = {};  // Object to de-duplicate bars by ID, keeping the most recent update

                // Iterate over each entry in the snapshot data
                Object.keys(data).forEach(key => {
                    const bar = data[key];
                    // Validate that data is a properly formed object and not an erroneous entry
                    if (typeof bar === 'object' && bar !== null && bar.id && bar.name) {
                        // If a newer timestamp is found, replace the older entry in barMap
                        if (!barMap[bar.id] || (barMap[bar.id].timestamp && bar.timestamp && barMap[bar.id].timestamp < bar.timestamp)) {
                            barMap[bar.id] = bar;
                        }
                    } else {
                        console.warn("Ignoring invalid entry for key:", key);
                    }
                });

                // Convert the map object to an array for easier manipulation
                const loadedBars = Object.values(barMap);

                // Calculate the minimum and maximum prices from the loaded bars
                const prices = loadedBars.map(bar => parseFloat(calculateFor01({ bar }))).filter(price => !isNaN(price));
                const minPrice = Math.min(...prices);
                const maxPrice = Math.max(...prices);

                // Update state with the loaded bars and their price range
                if (live) {
                    setBars(loadedBars);
                    setPriceRange({ min: minPrice, max: maxPrice });
                } else {
                    setBarsUnmoderated(loadedBars);
                    setPriceRangeUnmoderated({ min: minPrice, max: maxPrice });
                }
            } else {
                console.log("No data available");
                if (live) {
                    setBars([]);
                    setPriceRange({ min: Infinity, max: -Infinity });
                } else {
                    setBarsUnmoderated([]);
                    setPriceRangeUnmoderated({ min: Infinity, max: -Infinity });
                }

            }
        }).catch((error) => {
            console.error("Error fetching data:", error);
        });
    }

    // Effect hook to load data from Firebase on component mount
    useEffect(() => {
        loadData(true);
        loadData(false);
    }, []);  // The dependency array is empty, ensuring this effect runs only once after the component mounts

    // Function to update the selected bar ID
    const onSelectBar = (id) => {
        setSelectedBarId(id);
    };

    const onSelectUnmoderatedBar = (id) => {
        setSelectedUnmoderatedBarId(id);
    };

    const forceUpdate = () => {
        loadData(true);
        loadData(false);
    }

    // Layout of the component using Material-UI Box components
    return (
        <Box sx={{ display: 'flex', width: '100vw', height: '100vh' }}>
            {
                selectedBarId===null?null:<BarParentComponent moderation={false} barData={selectedBarId} onUpdate={forceUpdate} resetBarData={() => {
                    setSelectedBarId(null)
                }}/>
            }
            {
                selectedUnmoderatedBarId===null?null:<BarParentComponent moderation={true} barData={selectedUnmoderatedBarId} onUpdate={forceUpdate} resetBarData={() => {
                    setSelectedUnmoderatedBarId(null)
                }}/>
            }
            <Box sx={{ width: 400, backgroundColor: 'var(--color-accent-0)', overflowY: 'auto' }}>
                {/* Render BarList if bars are loaded */}
                {bars.length > 0 &&
                    <BarList
                        onUpdate={forceUpdate}
                        bars={bars}
                        priceRange={priceRange}
                        selectedBarId={selectedBarId}
                        onSelectBar={onSelectBar}
                        onSelectUnmoderatedBar={onSelectUnmoderatedBar}
                        selectedUnmoderatedBarId={selectedUnmoderatedBarId}
                        unmoderatedBars={barsUnmoderated}
                        unmoderatedPriceRange={priceRangeUnmoderated}
                    />
                }
            </Box>
            <Box sx={{ flexGrow: 1, position: 'relative' }}>
                {/* Render MainMap if bars are loaded */}
                {bars.length > 0 &&
                    <MainMap bars={bars} priceRange={priceRange} selectedBarId={selectedBarId} onSelectBar={onSelectBar} />}
                {/* SignInButton positioned absolutely within the relative container */}
                <Box sx={{ position: 'absolute', top: 16, right: 16, zIndex: 1000 }}>
                    <MaterialButtonTonal24 onClick={() => {
                        if (assistantIsOpen) {
                            setAssistantIsOpen(false)
                        } else {
                            setAssistantIsOpen(true)
                        }
                    }}>{assistantIsOpen ? "Close Assistant" : "Open Assistant"}</MaterialButtonTonal24>
                    &nbsp;&nbsp;&nbsp;
                    <SignInButton />
                </Box>
            </Box>
            {
                assistantIsOpen && bars.length > 0 ? <div className={"assistant-embedded"}>
                    <iframe src={"https://assistant.teslasoft.org/embedded?payload="+encodeURIComponent(btoa(JSON.stringify({
                        "name": payload.name,
                        "chatLocation": payload.chatLocation,
                        "icon": payload.icon,
                        "description": payload.description,
                        "initialMessage": "Act as an assistant for BeerMap - an interactive map of bars around the world. Provide your answers and suggestions based on the following data: ```" + unescape(encodeURIComponent(JSON.stringify(bars))) + "```.",
                        "initialResponse": "Hello! I am the BeerMap Assistant. I can help you with any questions you have about the bars on this site. Feel free to ask me anything!",
                        "systemMessage": "Pay attention to the bar data provided to you. Provide your answers and suggestions based on it!"
                    })))} className={"assistant-iframe"} title={payload.name}/>
                </div> : null
            }
        </Box>
    );
}

export default Home;
