/**
 * Do not modify this file.
 * */

import React from 'react';
import PropTypes from "prop-types";

// Props validation
MaterialWindow.propTypes = {
    children: PropTypes.node,
    name: PropTypes.string
}

function MaterialWindow({children, name, ...props}) {
    return (
        <div>
            {children}
        </div>
    );
}

export default MaterialWindow;