import React from 'react';
import {MaterialButton24, MaterialButtonOutlined24} from "../widgets/MaterialButton";

function Dialog({isOpen, setOpen, title, content, onPositiveButtonClicked, onNegativeButtonClicked, isConfirmDialog, positiveButtonText, negativeButtonText}) {
    return (
        <>
            {
                isOpen ? <div className={"priority backdrop"} onMouseDown={() => {
                    setOpen(false);
                    onNegativeButtonClicked();
                }}>
                    <div className={"dialog-paper"} onMouseDown={(e) => {
                        e.stopPropagation()
                    }}>
                        <p className={"dialog-title"}>{title}</p>
                        <div className={"dialog-content"}>{content}</div>
                        <div className={"dialog-actions"}>
                            {
                                isConfirmDialog ? <><MaterialButtonOutlined24 onClick={() => {
                                    setOpen(false);
                                    onNegativeButtonClicked();
                                }}>{negativeButtonText}</MaterialButtonOutlined24>&nbsp;&nbsp;&nbsp;</> : null
                            }
                            <MaterialButton24 onClick={() => {
                                setOpen(false);
                                onPositiveButtonClicked();
                            }}>{positiveButtonText}</MaterialButton24>
                        </div>
                    </div>
                </div>
                : null
            }
        </>
    );
}

export default Dialog;