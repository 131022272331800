import React, { useEffect, useRef } from 'react';
import { colors, getColorForPrice } from './GetColors';
import { calculateFor01 } from "./CalculateFor01";

// Function to create an SVG URL for the map markers
function createIconUrl(price, primaryColor, secondaryColor) {
    // Generates an SVG string and encodes it for use as a URL
    return `data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="65" height="24" viewBox="0 0 65 24">
        <rect x="0" y="0" width="64" height="24" rx="8" ry="8" fill="%23${primaryColor}" />
        <text x="31" y="14" fill="%23${secondaryColor}" alignment-baseline="middle"
        text-anchor="middle" font-size="12" font-family="Roboto, Arial, sans-serif">${price} €/dl</text></svg>`;
}

// MainMap component accepts props for bar data, price range, the selected bar ID, and a function to handle bar selection
const MainMap = ({ bars, priceRange, selectedBarId, onSelectBar }) => {
    const mapRef = useRef(null); // Ref for the DOM element where the map will be rendered
    const mapInstance = useRef(null); // Ref to store the instance of the map object
    const markerRefs = useRef(new Map()); // Ref to store a Map object for keeping track of marker instances

    useEffect(() => {
        // Initialize the map once the Google Maps script is loaded and the div ref is attached
        if (window.google && mapRef.current && !mapInstance.current) {
            console.log("MainMap useEffect");
            mapInstance.current = new window.google.maps.Map(mapRef.current, {
                center: { lat: 48.7164, lng: 21.2611 }, // Center the map on Košice by default
                zoom: 13,
                disableDefaultUI: true, // Disable default UI controls to customize
                zoomControl: true,
                mapTypeControl: true,
                scaleControl: true,
                streetViewControl: true,
                rotateControl: true,
                fullscreenControl: false,
                mapId: 'fd752bc7465398e6',
                mapTypeControlOptions: {
                    style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                    position: window.google.maps.ControlPosition.BOTTOM_CENTER,
                    mapTypeIds: ['roadmap', 'satellite', 'hybrid', 'terrain']
                },
                zoomControlOptions: {
                    position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
                    style: window.google.maps.ZoomControlStyle.SMALL
                },
                streetViewControlOptions: {
                    position: window.google.maps.ControlPosition.RIGHT_BOTTOM
                }
            });

            // Listen for clicks on the map background to deselect any selected marker
            mapInstance.current.addListener('click', () => {
                onSelectBar(null);
            });
        }

        // Add or update markers whenever the bars data or selected bar ID changes
        bars.forEach(bar => {
            addOrUpdateMarker(bar, mapInstance.current);
        });

    }, [bars]);

    // Function to add or update a marker for a bar
    const addOrUpdateMarker = (bar, map) => {
        const for01Price = calculateFor01({ bar });

        // Customize the color of the marker based on if it's selected
        const primaryColor = bar.id === selectedBarId ?
            colors('accent-800').slice(1) : getColorForPrice(for01Price, priceRange);
        const secondaryColor = bar.id === selectedBarId ?
            colors('accent-0').slice(1) : colors('primary-light').slice(1);

        const icon = {
            url: createIconUrl(for01Price, primaryColor, secondaryColor),
            scaledSize: new window.google.maps.Size(50, 30) // Size of the custom marker
        };

        let marker = markerRefs.current.get(bar.id);
        if (marker) {
            marker.setIcon(icon); // Update the icon if the marker already exists
            // If this marker is for the selected bar, center the map on it
            if (bar.id === selectedBarId) {
                map.setCenter(marker.getPosition());
                map.zoom = 16; // Zoom in when a bar is selected
            }
        } else {
            // Create a new marker if it does not exist
            marker = new window.google.maps.Marker({
                position: { lat: parseFloat(bar.lat), lng: parseFloat(bar.lng) },
                map: map,
                icon: icon,
                id: bar.id
            });

            // Add a click listener to the marker to select the bar and center the map
            marker.addListener('click', () => {
                onSelectBar(bar.id);
                map.setCenter(marker.getPosition());
                map.zoom = 16;
            });
            markerRefs.current.set(bar.id, marker);
        }
    };

    return <div ref={mapRef} style={{ height: '100%', width: '100%' }} />;
};

export default MainMap;
