/**
 * Add pages to this file.
 *
 * You can specify exact routes using the `exact` attribute.
 * Otherwise, the route will be matched if the path is a prefix of the URL.
 *
 * Don't forget to wrap your components with the MaterialWindow component.
 * */

import React from 'react';
import {Route, Routes} from "react-router-dom";
import MaterialWindow from "./components/MaterialWindow";
import Home from "./components/Home";
import Auth from "./components/Auth";
import Register from "./components/Register";

function BaseRoutes() {

    return (
        <Routes>
            <Route path="/" exact element={
                <MaterialWindow name={"home"}>
                    <Home/>
                </MaterialWindow>
            }/>
            <Route path="/auth" exact element={
                <MaterialWindow name={"home"}>
                    <Auth/>
                </MaterialWindow>
            }/>
            <Route path="/register" exact element={
                <MaterialWindow name={"home"}>
                    <Register/>
                </MaterialWindow>
            }/>
        </Routes>
    );
}

export default BaseRoutes;