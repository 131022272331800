import React from 'react';
import { Box, List, ListItem, Typography } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';

const ReviewList = ({ reviews }) => {
    // Sec. func. : Format timestamp
    const formatDate = (timestamp) => {
        // Convert:  UNIX timestamp --> RT date
        return new Date(timestamp * 1000).toLocaleDateString("en-US", {
            year: 'numeric', month: 'long', day: 'numeric'
        });
    };

    return (
        <Box sx={{
            overflow: 'auto',
            maxHeight: 300,
            bgcolor: 'var(--color-accent-200)',
            borderRadius: '16px',
            color: 'var(--color-secondary-light)'
        }}>
            <List>
                {reviews === undefined || reviews === null || reviews.length === 0 ? <p style={{
                        padding: "0 12px",
                        margin: "4px",
                    }}>No reviews yet</p> :
                    reviews.map(review => (
                        <ListItem key={review.id} sx={{ borderBottom: '1px solid var(--color-accent-200)', pb: 1, mb: 1 }}>
                            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                <AccountCircle sx={{ mr: 1, color: 'var(--color-primary-light)' }} />
                                <Typography variant="body2" style={{ color: 'var(--color-primary-light)', flexGrow: 1 }}>
                                    {review.name}: {review.comment}
                                </Typography>
                                <Box sx={{ // review RATING box
                                    bgcolor: 'var(--color-accent-200)',
                                    borderRadius: '20px',
                                    px: 2,
                                    display: 'inline-block'
                                }}>
                                    <Typography variant="caption" style={{ color: 'var(--color-secondary-light)' }}>
                                        Rating: {review.rating} | {formatDate(review.timestamp)}
                                    </Typography>
                                </Box>
                            </Box>
                        </ListItem>
                    ))
                }
            </List>
        </Box>
    );
}

export default ReviewList;