import React, {useState, useRef, useEffect} from 'react';
import {Box, CircularProgress, Typography} from '@mui/material';
import '../theme/colors.css'; // Import CSS for theme colors
import BarListItem from './BarListItem'; // Import the BarListItem component for individual bars
import {calculateFor01} from "./CalculateFor01"; // Import the function to calculate prices per deciliter
import AddForm from "./AddForm";
import {MaterialButton24Borderless} from "../widgets/MaterialButton";
import {FIREBASE_AUTH} from "../index";

// BarList component accepts props: list of bars, price range, selected bar ID, and a function to handle selection of a bar
const BarList = ({onUpdate, bars, priceRange, selectedBarId, onSelectBar, unmoderatedBars, unmoderatedPriceRange, selectedUnmoderatedBarId, onSelectUnmoderatedBar }) => {
    const [authState, setAuthState] = useState(1); // State to store the authentication state

    useEffect(() => {
        FIREBASE_AUTH.onAuthStateChanged(user => {
            setAuthState(user ? 2 : 3); // Set the state based on the user's authentication status
        })
    }, []);

    // Calculate prices for all bars first to optimize and avoid recalculating multiple times in render
    const prices = bars.map(bar => ({
        bar,
        price: parseFloat(calculateFor01({ bar })) // Calculate and parse the price for clarity and sorting
    }));

    const pricesUnmoderated = unmoderatedBars.map(bar => ({
        bar,
        price: parseFloat(calculateFor01({ bar }))
    }));

    // Sort bars based on the calculated prices in ascending order
    prices.sort((a, b) => a.price - b.price);

    pricesUnmoderated.sort((a, b) => a.price - b.price);

    const [showAddForm, setShowAddForm] = useState(false);

    const handleAddButtonClick = () => {
        setShowAddForm(true);
    };

    const handleFormClose = () => {
        setShowAddForm(false);
        onUpdate();
    };
    const barListRef = useRef(null); // Reference to the BarList container

    return (
        <div
            ref={barListRef}
            className={"barList"}>
            {/* Box for bar list and add button */}
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                {/* Title for the bar list */}
                <Typography variant="h3" component="h3" color='var(--color-secondary-light)'>
                    Bar List
                </Typography>

                {/* Button to add a new bar */}
                <div>
                    <MaterialButton24Borderless variant="contained" onClick={handleAddButtonClick} disabled={showAddForm}>
                        Add New Bar
                    </MaterialButton24Borderless>
                </div>
            </Box>
            {bars.length > 0 ? (
                // Container for the list of bars
                <Box sx={{
                    padding: '16px 0',
                    maxHeight: '100vh'
                }}>
                    {/* Map over the sorted prices and render a BarListItem for each */}
                    {
                        prices.length === 0 ? <div className={"loading-placeholder"}>
                                <CircularProgress sx={{
                                    color: 'var(--color-accent-900)',
                                    width: '72px',
                                    height: '72px'
                                }}/>
                            </div> :
                            prices.map(item => (
                                <BarListItem
                                    key={item.bar.id} // React key to optimize rendering
                                    bar={item.bar} // The bar data
                                    priceRange={priceRange} // Pass price range for possible use in the item
                                    isSelected={item.bar.id === selectedBarId} // Determine if the bar is selected
                                    onSelect={() => {
                                        setShowAddForm(false);
                                        onSelectBar(item.bar.id)
                                    }} // Function to call when a bar is selected
                                />
                            ))
                    }

                    {
                        authState === 2 ? <>
                            <hr style={{
                                width: '100%',
                                border: '0',
                                borderTop: '1px solid var(--color-accent-200)',
                                margin: '16px 0'
                            }}/>
                            <Typography variant="h5" component="h5" color='var(--color-primary-light)'>Awaiting moderation</Typography>
                            {
                                pricesUnmoderated.length === 0 ? <div>
                                        <Typography variant="p" component="p" color='var(--color-secondary-light)'>No changes to be moderated</Typography>
                                </div> :
                                    pricesUnmoderated.map(item => (
                                        <BarListItem
                                            key={item.bar.id}
                                            bar={item.bar}
                                            priceRange={unmoderatedPriceRange}
                                            isSelected={item.bar.id === selectedUnmoderatedBarId}
                                            onSelect={() => {
                                                setShowAddForm(false);
                                                onSelectUnmoderatedBar(item.bar.id)
                                            }}
                                        />
                                    ))
                            }
                        </> : <>
                            {
                                authState === 1 ? <div className={"loading-placeholder"}>
                                    <CircularProgress sx={{
                                        color: 'var(--color-accent-900)',
                                        width: '72px',
                                        height: '72px'
                                    }}/>
                                </div> : null
                            }
                        </>
                    }
                </Box>
            ) : (
                // Display message if no bars are available
                <p style={{ color: 'var(--color-secondary-light)' }}>No bars available.</p>
            )}
            {/* Component for uploading test data, uncomment if needed */}
            {/* <UploadTest /> */}

            {/* Conditionally render the form if showAddForm is true */}
            {showAddForm && <AddForm onClose={handleFormClose} />}
        </div>
    );
};

export default BarList;
