/**
 * WARNING: Do not modify this file.
 * */

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";

/* Firebase config */
/**
 * Using these Google Cloud API credentials for another projects is strictly prohibited!
 * */
const FIREBASE_APP_CONFIG = {
    apiKey: "AIzaSyB9R1cayYogT5C9iamA3QcmuxLUb-4VQ2M",
    authDomain: "beermap-98bfb.firebaseapp.com",
    databaseURL: "https://beermap-98bfb-default-rtdb.firebaseio.com",
    projectId: "beermap-98bfb",
    storageBucket: "beermap-98bfb.appspot.com",
    messagingSenderId: "860340425262",
    appId: "1:860340425262:web:0d4d07444ac979fbbc45bf",
    measurementId: "G-ZQ0P2GL84S"
};

// Initialize Firebase
// Usually you don't need to access this variable directly, but if you really need to, you can import it in another file.
// Add export keyword before const to export this variable before importing it in another files.
const FIREBASE_APP = initializeApp(FIREBASE_APP_CONFIG);

// Firebase services
export const FIREBASE_DATABASE = getDatabase(FIREBASE_APP);
export const FIREBASE_AUTH = getAuth(FIREBASE_APP);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);
